import { motion } from 'framer-motion';
import { useState } from 'react';
import Image from 'next/image';
import { parseImageURL } from '@utils/helpers';
import Link from 'next/link';
import { useRouter } from 'next/router';

function WorkExample(props) {
	const {
		title,
		slug,
		year,
		services,
		description,
		image,
		link,
		status,
		tech = [],
		isLink = false
	} = props;

	const router = useRouter();
	const [isOn, setIsOn] = useState(false);

	return (
		<div className="card card-work">
			<div className="card-content no-padding" style={{ overflow: 'hidden' }}>
				<motion.div
					onHoverStart={(e) => setIsOn(true)}
					onTap={(e) => setIsOn(!isOn)}
					onHoverEnd={(e) => setIsOn(false)}
					whileHover="hover"
					whileTap="hover"
					variants={{ hover: { scale: 1.1 } }}>
					<figure
						className="image is-1by1"
						onClick={() => router.push(`/portfolio/${slug}`)}>
						<Image src={parseImageURL(image)} alt={title} layout="fill" />
					</figure>

					<ul className="social-icons">
						{tech.map((e, i) => (
							<li key={i} data-tooltip={e === 'js' ? 'Javascript' : e}>
								<span className="icon is-large">
									<i className={`fab fa-${e}`} />
								</span>
							</li>
						))}
					</ul>
				</motion.div>
			</div>
			<footer className="jane">
				<motion.p
					className="is-size-5 has-text-dark"
					animate={isOn ? 'on' : 'off'}
					variants={{}}>
					<a href={link} target="_blank" className="has-text-dark">
						{title}
					</a>
				</motion.p>
				<p className="jane-footer-item">{description}</p>
			</footer>
		</div>
	);
}

export default WorkExample;
